import { API } from 'aws-amplify';
import { getContactDetails } from '../../graphql/queries';
// import { getAuthSession } from './../../cognitoAuthUtil';

export const contactDetails = async( email ) => {
    // await getAuthSession();
    const { data: { getContactDetails: contactDetails }, errors } = await API.graphql({ query: getContactDetails, variables: { email: email }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return contactDetails;
}

export const getContactDetailsService = async( email ) => {
    // await getAuthSession();
    const { data: { getContactDetails: contactDetails }, errors } = await API.graphql({ query: getContactDetails, variables: { email: email }});
    if( errors ) {
        return new Error( errors[0].message );
    }
    return contactDetails;
}